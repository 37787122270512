import React, { FC, useCallback, useState } from 'react'
import {
  MuiTelInput,
  MuiTelInputProps,
  MuiTelInputInfo,
  matchIsValidTel
} from 'mui-tel-input'
import styled from '@emotion/styled/macro'
import { Country } from 'utils/formConfig'

const DEFAULT_PREFERRED_COUNTRIES: MuiTelInputProps['preferredCountries'] = [
  Country.Morocco,
  Country.Mexico,
  Country.France,
  Country.India,
  Country.Australia,
  Country.Israel,
  // Country.Spain
]

const defaultMenuProps: MuiTelInputProps['MenuProps'] = {
  MenuListProps: {
    style: {
      width: '250px',
      background: 'rgb(5, 47, 55)',
      color: '#617b7f'
    }
  }
}

const StyledMuiTelInput = styled(MuiTelInput)`
  & input {
    font-family: 'Metropolis';
    font-size: 30px;
    color: ${({ theme }) => theme.colors.inputs.text};
    padding-top: 25px;

    :focus {
      color: ${({ theme }) => theme.colors.inputs.text};
    }

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      font-size: 24px;
    }
  }

  .MuiInput-root {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.inputs.border}`};

    &.Mui-focused {
      border-bottom: ${({ theme }) =>
        `1px solid ${theme.colors.inputs.borderFocused}`};
    }

    &.Mui-error {
      border-bottom: ${({ theme }) =>
        `1px solid ${theme.colors.inputs.borderError}`};
    }

    &::after {
      border-bottom: none;
    }

    &::before {
      display: none;
    }
  }

  .MuiTelInput-Flag {
    padding-top: 20px;
  }

  .MuiInputLabel-root {
    &.Mui-focused {
      color: ${({ theme }) => theme.colors.inputs.labelFocused};
    }

    color: ${({ theme }) => theme.colors.inputs.label};
    font-weight: normal;
    font-size: 22px;
    font-family: 'Metropolis';

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      font-size: 18px;
    }
  }
`

export const PhoneInput: FC<MuiTelInputProps> = ({
  preferredCountries,
  onChange,
  MenuProps,
  error,
  lang,
  ...rest
}) => {
  const [isInputValid, setIsInputValid] = useState(true)

  const handleChange = useCallback(
    (value: string, info: MuiTelInputInfo) => {
      onChange?.(value.replaceAll(' ', ''), info)
      setIsInputValid(matchIsValidTel(value))
    },
    [onChange]
  )

  return (
    <StyledMuiTelInput
      {...rest}
      error={!isInputValid || error}
      onChange={handleChange}
      preferredCountries={preferredCountries ?? DEFAULT_PREFERRED_COUNTRIES}
      onlyCountries={DEFAULT_PREFERRED_COUNTRIES}
      MenuProps={MenuProps ?? defaultMenuProps}
      focusOnSelectCountry
      flagSize="small"
      variant="standard"
      langOfCountryName={lang}
    />
  )
}
