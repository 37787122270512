import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext
} from 'react'
import {
  useJsApiLoader,
  GoogleMap,
  DrawingManager,
  Autocomplete
} from '@react-google-maps/api'
import styled from '@emotion/styled/macro'
import { CircularProgress } from '@mui/material'
import SearchIcon from 'assets/images/icon_map_search.svg'
import vls from 'utils/vls'
import { useTranslation } from 'react-i18next'
import MapControl from './MapControl'
import { AppContext } from 'core/AppContext'
import {
  buildDefaultPolygon,
  getLocationCoordinatesByCountry,
  PLANT_ONBOARDING_USER_LOCATION,
  PolygonBound
} from 'utils/formHelpers'
import MapMarkerIcon from 'assets/images/icon_map_marker_green.png'
import { PlotCenter } from 'utils/types'

const libraries: (
  | 'drawing'
  | 'places'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[] = ['drawing', 'places']


const polygonOptions = {
  strokeColor: 'rgb(220, 249, 0)',
  fillColor: 'rgb(220, 249, 0)',
  fillOpacity: 0.3,
  editable: false
}

const containerStyle = {
  width: '100%',
  height: '100%'
}

let center = {
  lat: 32.609473,
  lng: 35.290161
}

const MIN_ZOOM = 5
const ACCEPTED_LOCATION_ZOOM = 16
let zoomLevel = 10

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MagnifyingGlassIcon = styled.img`
  height: 22px;
  width: 22px;
`

const SearchContainer = styled.div`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`

const SearchPanContainer = styled.div`
  position: relative;
  display: flex;
  margin: 10px 10px;
  border-radius: 2px;
  max-width: 169px;
  width: fit-content;
  background: white;

  @media (pointer: none), (pointer: coarse) {
    max-width: 193px;
  }
`

const StyledPlaceInput = styled.input`
  transition-duration: 300ms;
  width: 0px;
  font-size: 16px;
  height: 40px;
  border: none;
  border-radius: 2px;
  padding: 1px 2px;
  width: 129px;

  @media (pointer: none), (pointer: coarse) {
    padding: 1px 2px;
    width: 153px;
  }
`

interface PolygonDrawingMapProps {
  defaultLocation?: PlotCenter
  generatedPolygon?: { lat: number | undefined, lng: number | undefined }[] | null
  polygonOnChange: (lat: number, lng: number, polygon: PolygonBound[] | undefined) => void
  onMarkerDrawn: (
    newCoords: PlotCenter | undefined,
    polygon: PolygonBound[] | undefined,
    displayQuestion: boolean,
    displayPolygon: boolean,
    preventCompletion?: boolean
  ) => void
}

const PolygonDrawingMap = ({
  defaultLocation,
  generatedPolygon,
  polygonOnChange,
  onMarkerDrawn
}: PolygonDrawingMapProps) => {
  const [map, setMap] = useState<any>(null)
  const [drawingMode, setDrawingMode] = useState<google.maps.drawing.OverlayType | null>(null)
  const { i18n } = useTranslation()
  const lang = useMemo(() => i18n.language, [i18n.language])
  const userCountryData = useContext(AppContext)
  const [mapCenter, setMapCenter] = useState(center)
  const [userLocation, setUserLocation] = useState<
    { lat: number; lng: number } | undefined
  >(undefined)
  const [customZoomLevel, setCustomZoomLevel] = useState(zoomLevel)
  // const [geolocationPermitted, setGeolocationPermitted] = useState(false)
  const [placeResult, setPlaceResult] = useState<
    google.maps.places.Autocomplete | undefined
  >(undefined)
  const [inputChanged, setInputChanged] = useState(false)
  const placeTextRef = useRef<HTMLInputElement>(null)
  const currentMarker = useRef<google.maps.Marker | null>(null)
  const currentPolygon = useRef<google.maps.Polygon | null>(null)

  const handleClickSearch = useCallback(() => {
    placeTextRef.current?.focus()
  }, [])

  useEffect(() => {
    if (defaultLocation) {
      return
    }

    const storedUserLocation = vls.get(PLANT_ONBOARDING_USER_LOCATION)
    const defaultLocationByCountry = getLocationCoordinatesByCountry(
      userCountryData.country
    )
    center = {
      lat: storedUserLocation?.lat || defaultLocationByCountry.lat,
      lng: storedUserLocation?.lng || defaultLocationByCountry.lng
    }

    if (storedUserLocation?.lat && storedUserLocation?.lng) {
      setCustomZoomLevel(ACCEPTED_LOCATION_ZOOM)
    }

    setMapCenter(center)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, userCountryData.country])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: libraries as any
  })

  const changeDrawingMode = useCallback(() => {
    setDrawingMode(
      defaultLocation
        ? null
        : google.maps.drawing?.OverlayType.MARKER || null
    )
  }, [defaultLocation])

  const onLoadAutocomplete = useCallback((AC: any) => {
    setPlaceResult(AC)
  }, [])

  const onPlaceChanged = useCallback(() => {
    const placeLocation = {
      lat: placeResult?.getPlace()?.geometry?.location?.lat(),
      lng: placeResult?.getPlace()?.geometry?.location?.lng()
    }

    map.panTo(placeLocation)
  }, [map, placeResult])

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      changeDrawingMode()
      setMap(map)
    },
    [changeDrawingMode]
  )

  const onUnmount = useCallback(() => {
    const currentCenter = map.getCenter()
    center = currentCenter ? { lat: currentCenter.lat(), lng: currentCenter.lng() } : center
    setMapCenter(currentCenter ? { lat: currentCenter.lat(), lng: currentCenter.lng() } : center)
    zoomLevel = map.getZoom()
    setMap(null)
    setPlaceResult(undefined)
  }, [map])

  useEffect(() => {
    changeDrawingMode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLocation])

  const repositionAutoComplete = useCallback(() => {
    const elements = document.getElementsByClassName('pac-container')
    for (let i = 0; i < elements.length; i++) {
      document.getElementById('poly-map-container')?.appendChild(elements[i])
    }
  }, [])

  useEffect(() => {
    repositionAutoComplete()
  }, [placeResult, inputChanged, repositionAutoComplete])

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        vls.set(PLANT_ONBOARDING_USER_LOCATION, {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      })
    }
  }, [])

  useEffect(() => {
    if (map && userLocation) {
      setCustomZoomLevel(ACCEPTED_LOCATION_ZOOM)
      map.panTo(userLocation)
    }
  }, [map, userLocation])

  // useEffect(() => {
  //   navigator.permissions.query({ name: 'geolocation' }).then((result) => {
  //     setGeolocationPermitted(result.state === 'granted')
  //     result.addEventListener('change', () => {
  //       setGeolocationPermitted(result.state === 'granted')
  //     })
  //   })
  // }, [])

  const onMarkerComplete = useCallback(
    (marker: google.maps.Marker) => {
      if (map.getZoom() < ACCEPTED_LOCATION_ZOOM) {
        marker?.setMap(null)
        return
      }

      currentMarker.current?.setMap(null)
      marker.setIcon(MapMarkerIcon)
      currentMarker.current = marker
      const markerPosition = marker?.getPosition()
      const temporaryPolygonBounds = buildDefaultPolygon(
        markerPosition?.lat(),
        markerPosition?.lng(),
        map.getZoom(),
        MIN_ZOOM,
        ACCEPTED_LOCATION_ZOOM
      )

      if (markerPosition?.lat() && markerPosition?.lng()) {
        polygonOnChange(markerPosition?.lat(), markerPosition?.lng(), temporaryPolygonBounds)
        return
      }
    },
    [map, polygonOnChange]
  )

  useEffect(() => {
    if (!isLoaded) {
      return
    }

    if (!defaultLocation && currentMarker.current) {
      currentMarker.current.setMap(null)
      return
    }

    if (map && defaultLocation && defaultLocation.longitude != null && defaultLocation.latitude != null && !currentMarker.current) {
      currentMarker.current = new google.maps.Marker(
        { position: { lat: defaultLocation.latitude, lng: defaultLocation.longitude }, icon: MapMarkerIcon }
      )
      currentMarker.current.setMap(map)
    }
  }, [defaultLocation, isLoaded, map])

  useEffect(() => {
    if (!isLoaded) {
      return
    }

    if (!currentMarker.current && !generatedPolygon && currentPolygon.current) {
      currentPolygon.current.setMap(null)
    }

    if (!defaultLocation) {
      currentPolygon?.current?.setMap(null)
    }

    if (defaultLocation && generatedPolygon && map) {
      currentPolygon.current = new google.maps.Polygon({
        paths: generatedPolygon,
        ...polygonOptions
      })
      currentPolygon.current.setMap(map)
    }

    return () => {
      if (currentPolygon.current) {
        currentPolygon.current.setMap(null)
      }
    }
  }, [map, generatedPolygon, isLoaded, defaultLocation])


  if (!isLoaded) {
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    )
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={customZoomLevel}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapTypeId: 'hybrid',
        minZoom: MIN_ZOOM,
        mapTypeControl: false,
        mapTypeControlOptions: { mapTypeIds: [] }
      }}
    >
      <DrawingManager
        options={{
          drawingMode,
          drawingControl: false,
          drawingControlOptions: {
            drawingModes: [google.maps.drawing.OverlayType.MARKER]
          },
        }}
        onMarkerComplete={onMarkerComplete}
      />
      {/* <MapControl position="TOP_RIGHT" map={map}>
        {!!geolocationPermitted && (
          <GeoLocationIcon onClick={panToCurrentLocation}>CL</GeoLocationIcon>
        )}
      </MapControl> */}
      <MapControl position="LEFT_TOP" map={map}>
        <Autocomplete
          onLoad={onLoadAutocomplete}
          onPlaceChanged={onPlaceChanged}
        >
          <SearchPanContainer id="poly-map-container">
            <SearchContainer onClick={handleClickSearch}>
              <MagnifyingGlassIcon src={SearchIcon} />
            </SearchContainer>
            <StyledPlaceInput
              onChange={() => {
                setInputChanged(true)
              }}
              ref={placeTextRef}
            />
          </SearchPanContainer>
        </Autocomplete>
      </MapControl>
    </GoogleMap>
  )
}

export default memo(PolygonDrawingMap)
